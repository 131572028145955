import { getCookie, setCookie } from 'helpers/manageCookies';
import { cookieKeysEnum } from 'models/common.models';
import React from 'react';
import { Alert } from 'react-bootstrap';

export const BannerContext = React.createContext({
    openBanner: (bannerData) => { },
    setIsBannerValidForUrlInfo: () => { }
});

export const BannerProvider = ({ children }) => {
    const [bannerInfo, setBannerInfo] = React.useState({});
    const [isBannerValidForUrl, setIsBannerValidForUrlInfo] = React.useState(true);

    React.useEffect(() => {
        if (getCookie(cookieKeysEnum["BANNER-DISPLAY"])) {
            setBannerInfo(JSON.parse(getCookie(cookieKeysEnum["BANNER-DISPLAY"])));
        }
    }, []);

    const bannerValues = React.useMemo(() => {
        return {
            openBanner: (bannerData) => {
                setBannerInfo(bannerData);
            },
            setIsBannerValidForUrlInfo
        };
    }, [setBannerInfo, setIsBannerValidForUrlInfo]);

    const onCloseBanner = () => {
        const updatedBannerInfo = { ...bannerInfo, isShowAlertBanner: false };
        setCookie(cookieKeysEnum["BANNER-DISPLAY"], JSON.stringify(updatedBannerInfo));
        setBannerInfo(updatedBannerInfo);
    };
    return (
        <BannerContext.Provider value={bannerValues}>
            <>
                {bannerInfo.isShowAlertBanner &&
                    <Alert onClose={onCloseBanner} variant={bannerInfo.alerttype} className={'astm-alert bg-' + bannerInfo.alerttype + '--light'} closeLabel={''} dismissible>
                        <div className='flex-box' data-testid = 'alert-message'>
                            <span className="astm-alert-text">{bannerInfo.alertmessage}</span>
                        </div>
                    </Alert>
                }
            </>
            {children}</BannerContext.Provider>


    );
};

export const withBannerHooks = (Component) => {
    return (props) => {
        const banner = useBanner();
        return <Component  {...props} {...banner} />;
    };
};

export const useBanner = () => React.useContext(BannerContext);