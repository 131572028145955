import crypto from 'crypto-browserify';
import CryptoJS from 'crypto-js';
import html2canvas from "html2canvas";
import { get, isEmpty, isString } from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';
import { Buffer } from 'buffer';
import { nameFormatTypeEnum } from '../models/common.models';
import moment from 'moment';
import { cookieKeysEnum, AddressValidationStatusEnum } from '../models/common.models';
import { countryCodesObj, emailRegex, numRegex, ValidationMessage, zipCodeCARegex, zipCodeRegex, zipCodeUSRegex, membershipTypeJSON, USER_INFO, IDENTITY_DELEGATION_FLAG } from './constant';
import { getCookie, setCookie } from "./manageCookies";

const securityKey = 'aes-256-cbc';
//Meeting and symposia component
export const canceledLabel = 'CANCELED';

export const encrypt = (text) => {
    try {
        if (text && !process.env.testmode) {
            let iv = crypto.randomBytes(Number(process.env.REACT_APP_STORAGE_IV_LENGTH));
            let cipher = crypto.createCipheriv(securityKey, Buffer.from(process.env.REACT_APP_STORAGE_VALUE), iv);
            let encrypted = cipher.update(text);
            encrypted = Buffer.concat([encrypted, cipher.final()]);
            return iv.toString('hex') + ':' + encrypted.toString('hex');
        }
        return text;
    } catch (e) {
        console.log("Exception in Encryption", e);
    }
};

export const decrypt = (text) => {
    try {
        if (text && !process.env.testmode) {
            let textParts = text.split(':');
            if (textParts && textParts.length > 0) {
                let iv = Buffer.from(textParts.shift(), 'hex');
                let encryptedText = Buffer.from(textParts.join(':'), 'hex');
                let decipher = crypto.createDecipheriv(securityKey, new Buffer(process.env.REACT_APP_STORAGE_VALUE), iv);
                let decrypted = decipher.update(encryptedText);
                decrypted = Buffer.concat([decrypted, decipher.final()]);
                return decrypted.toString();
            }
        }
        return text;
    } catch (e) {
        console.log("Exception in Decryption", e);
    }
};

export const cacheManager = {
    getItem: (key, isDecrypt = true) => {
        if (isDecrypt) {
            return decrypt(window.localStorage.getItem(key));
        }
        return window.localStorage.getItem(key)

    },
    getSessionItem: (key, isDecrypt = true) => {
        if (isDecrypt) {
            return decrypt(sessionStorage.getItem('okta-token-storage') ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key));
        }
        return sessionStorage.getItem('okta-token-storage') ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key)

    },
    setItem: (key, value) => {
        let string = isString(value) ? encrypt(value) : encrypt(JSON.stringify(value));
        window.localStorage.setItem(key, string);
    },
    removeItem: (key) => {
        window.localStorage.removeItem(key)
    },
    clearSession: () => {
        window.localStorage.clear()
    }
}

export const setSession = (key, value) => {
    window.localStorage.setItem(key, value)
};

export const getSession = (key, value) => {
    return window.localStorage.getItem(key)
};

export const removeSession = (key) => {
    window.localStorage.removeItem(key)
};

export const clearSession = () => {
    window.localStorage.clear()
    window.sessionStorage.clear();
};

export const getFormatDate = (date, format = 'MM/DD/YYYY') => {
    return moment(date).format(format)
};

export const getFormatDateMMMMDDYYYY = (date) => {
    return moment(date).format('MMMM DD, YYYY')
};

export const getTimeInfo = (date) => {
    let timeFormat = moment(date).format('A');
    let hours = moment(date).format('HH');

    if (timeFormat === 'PM') {
        hours = Number(hours) - 12;

        if (hours < 10) {
            hours = `0${hours}`;
        }
    }

    return {
        hours,
        minutes: moment(date).format('mm'),
        timeFormat
    }
}

export const getFomattedUTCDateAndTime = (date, format) => {
    return moment.utc(moment(date)).format(format);
}

export const setRedirectUrl = (value) => {
    setCookie(cookieKeysEnum["REDIRECT-URL"], value, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
}

export const getRedirectUrl = () => {
    const value = getCookie(cookieKeysEnum["REDIRECT-URL"]);    
    return value;
}

export const memberNameFormat = (firstname, middlename, lastname, formatType = '') => {
    lastname = lastname ? lastname.trim() : "";
    firstname = firstname ? firstname.trim() : "";
    middlename = middlename ? middlename.trim().substr(0, 1) : "";
    switch (formatType) {
        case nameFormatTypeEnum.FL:
            return `${firstname} ${lastname}`;
        case nameFormatTypeEnum.LF:
            return `${lastname}, ${firstname}`;
        case nameFormatTypeEnum.LFM:
            return `${lastname}, ${firstname} ${middlename}`;
        case nameFormatTypeEnum.FML:
            return `${firstname} ${middlename} ${lastname}`
        default:
            return `${lastname}, ${firstname} ${middlename}`
    }
};

export const memberNickFormat = (nickname, firstname) => {
    nickname = nickname ? nickname.trim() : "";
    firstname = firstname ? firstname.trim() : "";
    if (!isEmpty(nickname)) {
        return nickname;
    }
    if (!isEmpty(firstname)) {
        return firstname.length > 15 ? firstname.substr(0, 15) + '...' : firstname;
    }
    return '';
};

export const encryptData = (data, key) => {
    const encrypted = encryptText(data);
    window.localStorage.setItem(key, encrypted);
}

export const encryptText = (data) => {
    const str = JSON.stringify(data);
    const randomKey = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(str, randomKey).toString();
}

export const decryptData = (key) => {
    const randomKey = process.env.REACT_APP_SECRET_KEY;
    const encrypted = window.localStorage.getItem(key);
    if (!encrypted) {
        return null;
    }
    let decrypted = CryptoJS.AES.decrypt(encrypted, randomKey)
    let decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
    let decryptedObj = JSON.parse(decryptedStr);
    return decryptedObj;
}

export const removeRegFromString = (str, reg) => {
    if (str) {
        const regObj = new RegExp(reg);
        return str.replace(regObj, '');
    }
    return null;
}

export const multipleOrganizationList = (organizations) => {
    let list = organizations.map((organization) => {
        if (organization.CompanyName) {
            return organization.CompanyName
        }
        else {
            return organization;
        }
    });
    return list
}

export const validateCompanySearch = value => {
    if (isEmpty(value)) {
        return ValidationMessage.RequiredField;
    }

    if (isString(value) && value.trim().length === 0) {
        return ValidationMessage.RequiredField;
    }

    if (!isString(value) && isEmpty(value.CompanyName)) {
        return ValidationMessage.RequiredField;
    }

    return false;
}

export const required = value => (value && value.trim() ? undefined : "Required");

export const requiredEmail = value => (value && value.trim() ? undefined : ValidationMessage["RequiredField"]);

export const incorrectEmail = value => (value && !emailRegex.test(value) ? ValidationMessage["InvalidEmail"] : undefined);

// Membership Application Forms Validations
export const validateRequiredText = (value, allValues, props, name) => value && value.trim() ? null : props.validationMessages.required;

export const validateRequiredSelect = (value, allValues, props, name) => value && value.trim() && value !== "0" ? null : props.validationMessages.required;

export const validateIsAgree = (value, allValues, props, name) => {
    return value ? undefined : props.validationMessages.requiredVolumeAgree;
}

export const validateNumber = (value, allValues, props, name) => value && numRegex.test(value) ? null : props.validationMessages.invalidNumber;

export const validateEmail = (value, allValues, props, name) => value && emailRegex.test(value) ? null : props.validationMessages.invalidEmail;

export const validateRequiredZip = (value, allValues, props, name) => {
    const { countryCode } = props;
    if (!value && (countryCode === countryCodesObj.US.code || countryCode === countryCodesObj.CA.code)) {
        return props.validationMessages.required;
    }
    return null;
}

export const validateZip = (value, allValues, props, name) => {
    const { countryCode } = props;
    if (countryCode === countryCodesObj.US.code) {
        return value && zipCodeUSRegex.test(value) ? null : props.validationMessages.invalidZipCode;
    }
    if (countryCode === countryCodesObj.CA.code) {
        return value && zipCodeCARegex.test(value) ? null : props.validationMessages.invalidZipCode;
    }
    return !value || zipCodeRegex.test(value) ? null : props.validationMessages.invalidZipCode;
}

export const validateAddressSearch = (value, allValues, props, name) => {
    if (isEmpty(value)) {
        return props.validationMessages.required;
    }

    if (isString(value) && !value.trim()) {
        return props.validationMessages.required;
    }

    if (!isString(value) && isEmpty(value.AddressLine1)) {
        return props.validationMessages.required;
    }

    return false;
}

export const validateCompanyMultiSearch = (value, allValues, props, name) => isEmpty(value) ? props.validationMessages.required : null;

export const getCheckoutPayload = (payload) => {
    const {
        committeeDetails,
        removedCommitteeId,
        demoGraphy,
        membershipOrderType,
        membershipData,
        selectedVolume,
        copyrightAcceptanceDateTime,
        testKey
    } = payload;

    const lineItems = {
        memStock: membershipData.stockCode
    };

    if (!isEmpty(selectedVolume)) {
        lineItems.volumeStock = selectedVolume.id;
    }
    const checkoutPayload = {
        membershipformdata: {
            memberAccountNumber: membershipData.memberAccountNumber,
            memberTypeId: membershipData.memberTypeId,
            membershipTypeCode: membershipData.membershipTypeCode,
            membershipOrderType,
            lineItems,
            memberData: {
                "firstName": demoGraphy.FirstName,
                "middleInitial": demoGraphy.MiddleName ? demoGraphy.MiddleName : null,
                "lastName": demoGraphy.LastName,
                "phone": demoGraphy.Phone,
                "email": demoGraphy.Email,
                "address": {
                    "addressLine1": demoGraphy.AddressLine1,
                    "addressLine2": demoGraphy.AddressLine2,
                    "city": demoGraphy.City,
                    "countryCode": demoGraphy.CountryCode,
                    "countryName": demoGraphy.Country,
                    "stateCode": isEmpty(demoGraphy.StateCode) ? null : demoGraphy.StateCode,
                    "stateName": isEmpty(demoGraphy.State) ? null : demoGraphy.State,
                    "zipCode": demoGraphy.PostalCode
                }
            },
            companyData: isEmpty(demoGraphy.Company) ? null : {
                "name": demoGraphy.Company.CompanyName,
                "address": {
                    "addressLine1": demoGraphy.Company.AddressLine1,
                    "addressLine2": demoGraphy.Company.AddressLine2,
                    "city": demoGraphy.Company.City,
                    "countryCode": demoGraphy.Company.CountryCode,
                    "countryName": demoGraphy.Company.CountryName,
                    "stateCode": isEmpty(demoGraphy.Company.StateCode) ? null : demoGraphy.Company.StateCode,
                    "stateName": isEmpty(demoGraphy.Company.StateName) ? null : demoGraphy.Company.StateName,
                    "zipCode": demoGraphy.Company.PostalCode
                },
                "divisionName": demoGraphy.DivisionName,
                "parentOrganization": demoGraphy.ParentOrganization,
                "organizationWebsite": demoGraphy.OrganizationWebsite
            }
        },
        checkoutData: {
            memberAccountNumber: membershipData.memberAccountNumber,
            membershipTypeCode: membershipData.membershipTypeCode,
            membershipTypeName: membershipData.membershipTypeName,
            membershipOrderType,
            lineItems,
            otherDetails: {
                "firstname": demoGraphy.FirstName,
                "lastname": demoGraphy.LastName,
                "middlename": demoGraphy.MiddleName ? demoGraphy.MiddleName : null,
                "company": !isEmpty(demoGraphy.Company) ? demoGraphy.Company.CompanyName : null,
                "email": demoGraphy.Email,
                "telephone": demoGraphy.Phone,
                "postcode": demoGraphy.PostalCode,
                "country_id": demoGraphy.CountryCode,
                "region": demoGraphy.StateCode ? demoGraphy.StateCode : demoGraphy.State ? demoGraphy.State : null,
                "city": demoGraphy.City,
                "street": [demoGraphy.AddressLine1]
            }
        },
        testKey
    };

    if (!isEmpty(demoGraphy.AddressLine2)) {
        checkoutPayload.checkoutData.otherDetails.street.push(demoGraphy.AddressLine2);
    }

    if (!isEmpty(selectedVolume)) {
        checkoutPayload.membershipformdata.selectedVolume = {
            productVolume: selectedVolume.code,
            productformat: selectedVolume.format
        };
    }

    if (copyrightAcceptanceDateTime) {
        checkoutPayload.membershipformdata.copyrightAcceptanceDateTime = copyrightAcceptanceDateTime;
    }

    // for reinstate case
    if (!isEmpty(committeeDetails)) {
        checkoutPayload.membershipformdata.committeeDetails = committeeDetails;
    }

    // for reinstate case
    if (!isEmpty(removedCommitteeId)) {
        checkoutPayload.membershipformdata.removedCommitteeId = removedCommitteeId;
    }

    return checkoutPayload;
}

export const encodeText = (text) => {
    return window.btoa(text);
}

export const isLoggerEnabled = () => {
    return process.env.REACT_APP_IS_LOGGER_ENABLED === "true";
}

export const getStandardsUrlFromAssetId = (assetId) => {
    return assetId ? `${process.env.REACT_APP_PUB_URL}/${assetId}.html` : '';
}

export const getEncodedWorkItemNumberWithoutWK = (workItemNumber) => {
    workItemNumber = workItemNumber.substring(2);

    return workItemNumber ? encrypt(workItemNumber.toString()) : encrypt('0');
}
export const downloadFile = (docPath, fileUrl, openNewTab = true) => {
    const link = document.createElement("a");

    const fileNameSplit = docPath ? docPath.split('/') : [];
    if (fileNameSplit.length > 0) {
        link.download = fileNameSplit[fileNameSplit.length - 1];
    }
    let type = docPath.split('.').pop() || ''
    if (openNewTab && type.toLowerCase() === 'pdf') {
        link.target = "_blank";
    }

    // Construct the URI
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();

    // Cleanup the DOM
    document.body.removeChild(link);
}

export const downloadS3File = (fileUrl) => {
    const link = document.createElement("a");
    // Construct the URI
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    // Cleanup the DOM
    document.body.removeChild(link);
}

// TODO : session storage manager.
export const sessionStorageManager = {
    getItem: (key, isDecrypt = true) => {
        if (isDecrypt) {
            return decrypt(window.sessionStorage.getItem(key));
        }
        return window.sessionStorage.getItem(key)

    },
    setItem: (key, value) => {
        let string = isString(value) ? encrypt(value) : encrypt(JSON.stringify(value));
        window.sessionStorage.setItem(key, string);
    },
    removeItem: (key) => {
        window.sessionStorage.removeItem(key)
    }
};

export const hasOpenAuthToken = () => {
    if (!isEmpty(getCookie('open-auth-token'))) {
        return getCookie('open-auth-token');
    }
    return false;
}

export const AddGoogleDocLog = (fileName) => {
    // Google Analytics code
    let dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'documentClick', 'detail': {
            'documentName': fileName,
            'documentId': '',
            'version': '',
            'status': 'most recent',
            'industry': '',
            'documentPages': '',
            'publishDate': getcurday('/'),
            'custom': ''
        }
    });
}

export const getcurday = (sp) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd < 10) { dd = '0' + dd };
    if (mm < 10) { mm = '0' + mm };
    return (mm + sp + dd + sp + yyyy);
}

//TODO : get userInfo from cookies.
export const getUserInfo = () => {
    if (cacheManager.getItem('user-info')) {
        return JSON.parse(cacheManager.getItem('user-info'));
    }
    return null;
}

//TODO : check okta storage available in localstorage.
export const isOKTAStorageAvailable = (isAndOperator = true) => {
    if (isAndOperator) {
        // This condition required for validate redirect in public application.
        if (cacheManager.getSessionItem('okta-token-storage', false) && cacheManager.getSessionItem('okta-cache-storage', false)) {
            return true;
        }
    } else {
        // This condition required for validate open api call only.
        if (cacheManager.getSessionItem('okta-token-storage', false) || cacheManager.getSessionItem('okta-cache-storage', false)) {
            return true
        }
    }
    return false;
}

//TODO : This function is used check okta storage available in localstorage with key existance validation(to handle stale login case) .
export const isOKTAStorageAvailableWithRequiredkey = () => {
    if ( cacheManager.getItem("okta-token-storage", false) && cacheManager.getItem("okta-cache-storage", false)) {
      var okta_token_storage = JSON.parse(cacheManager.getItem("okta-token-storage", false));
      if (
          typeof okta_token_storage !== "undefined"  &&
          typeof okta_token_storage.idToken !== "undefined" &&
          typeof okta_token_storage.accessToken !== "undefined"
      ) {
        return true;
      }
    }
    return false;
};

export const getMessage = (messageList, message) => {
    return get(messageList, message);
}

export const convertBytesToKB = (sizeInBytes) => {
    let sizeInKB = (sizeInBytes / (1024)).toFixed(2);
    return sizeInKB;
}

export const days_diff = (fromDate) => {
    const date1 = new Date(new Date());
    const date2 = new Date(fromDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return (date1 > date2 ? '' : '-') + diffDays;
}

export const generateUUID = (log = false) => {
    if (log) {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    } else {
        let dt = new Date().getTime();
        let uuid = 'xxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
}

export const getEmailDomain = (email) => {
    if (email && email.trim().length > 0) {
        return email.substring(email.lastIndexOf('@') + 1)
    }

    return '';
}

export const scrollToTop = () => {
    if (document.querySelector('body').scrollTo) {
        document.querySelector('body').scrollTo(0, 0)
    }
}

export const scrollToError = () => {
    const el = document.querySelector('.is-invalid')
    if (el) {
        el.scrollIntoView(true);
    }
}

// Function to get formatted date and check if its greater than or equal to todays date
export const validateDate = (date) => {
    const newDate = new Date(date);
    const todaysDate = new Date();

    return todaysDate.getTime() > newDate.getTime() ? null : newDate
};

export const characterCount = (data, charlength) => {
    if (data.length > 0 && charlength)
        data = data.substr(0, charlength) + '...';
    return data;
}

export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || "";

export const getCanvas = page => {
    return html2canvas(page).then((canvas) => canvas);
}

export const isLocalhost = () => {
    return Boolean(window.location.hostname === 'localhost'
        || window.location.hostname === '[::1]'
        || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );
}

export const isOKTAStateAndNonceExists = () => {
    if (getCookie(cookieKeysEnum['IS-OKTA-VERIFIED'])) {
        return true;
    }
    return false;
}

export const getUserAgent = () => {
    const test = (regexp) => regexp.test(window.navigator.userAgent);
    switch (true) {
        case test(/edg/i): return { name: 'Edge', version: '' };
        case test(/trident/i): return { name: 'IE', version: '' };
        case test(/firefox|fxios/i): return { name: 'Firefox', version: '' };
        case test(/opr\//i): return { name: 'Opera', version: '' };
        case test(/ucbrowser/i): return { name: 'UC', version: '' };
        case test(/samsungbrowser/i): return { name: 'Samsung', version: '' };
        case test(/chrome|chromium|crios/i): return { name: 'Chrome', version: '' };
        case test(/safari/i): return { name: 'Safari', version: '' };
        default: return { name: 'Other', version: '' };
    }
}

// Common Handler to scroll on error on form field.
export const scrollToErrorOnFormHandler = () => {
    const fields = 'input, select, textarea, .sidebar-selection-input'; // Increase Fields as per requirement.
    const currentFormSelector = document.querySelectorAll('form');
    // Wait for is-Invalid class attached into the fields.
    setTimeout(() => {
        // Get all form into single screen.
        for (let index = 0; index < currentFormSelector.length; index++) {
            if (currentFormSelector[index]) {
                const fieldsElements = currentFormSelector[index].querySelectorAll(fields);
                // Get all fields from Form references.
                for (let i = 0; i < fieldsElements.length; i++) {
                    if ((fieldsElements[i].classList && fieldsElements[i].classList.value.indexOf('is-invalid') > -1)
                        || (fieldsElements[i].offsetParent && fieldsElements[i].offsetParent.classList && fieldsElements[i].offsetParent.classList.value.indexOf('is-invalid') > -1)
                        || (fieldsElements[i].nextElementSibling && fieldsElements[i].nextElementSibling.classList && fieldsElements[i].nextElementSibling.classList.value.indexOf('is-invalid') > -1)
                        || (fieldsElements[i].lastChild && fieldsElements[i].lastChild.classList && fieldsElements[i].lastChild.classList.value.indexOf('is-invalid') > -1)
                    ) {
                        fieldsElements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
                        return false;
                    }
                }
            }
        }
    }, 100);
}

// Handle to Jump Cursor on positions. 
export const handleTypeHeadCursorPosition = (id, field = 'input') => {
    if (id) {
        document.getElementById(id).addEventListener(field, (event) => {
            const caret = event.target.selectionStart
            const element = event.target
            window.requestAnimationFrame(() => {
                setTimeout(() => {
                    element.selectionStart = element.selectionEnd = caret;
                }, 10);
            })
        });
    }
}

export const getFileType = (fileName) => {

    let lastIndex = fileName.lastIndexOf('.');

    if ((fileName.length > lastIndex + 1) && lastIndex > 0) {
        return fileName.substring(lastIndex + 1).toLowerCase();
    }
    return "";
}

export const checkIsUserCOARep = userType => {

    let isCOAType = false
    const { id: getStaticCOARepID } = membershipTypeJSON.CooperativeAgreementRep
    if (getStaticCOARepID === userType) {
        isCOAType = true
    }
    return isCOAType

}

export const doAddressValidation = (addressMember, addressOrganization, addressValidation) => {
    let result = {
        MemberAddress: [],
        OrganizationAddress: [],
        type: 0
    };
    return new Promise((resolve, reject) => {
        if (addressMember !== null) {
            addressValidation(addressMember, response => {
                result.MemberAddress = { status: response.status, validateAddress: response.validateAddress };
                if (addressOrganization === null) {
                    if (result.MemberAddress.status === AddressValidationStatusEnum.EXACT_MATCH) {
                        result.type = 1;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.CLEANSED) {
                        result.type = 4;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.ERROR) {
                        result.type = 7;
                    }
                    return resolve(result);
                }
                addressValidation(addressOrganization, resp => {
                    result.OrganizationAddress = { status: resp.status, validateAddress: resp.validateAddress };
                    if (result.MemberAddress.status === AddressValidationStatusEnum.EXACT_MATCH && result.OrganizationAddress.status === AddressValidationStatusEnum.EXACT_MATCH) {
                        result.type = 1;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.EXACT_MATCH && result.OrganizationAddress.status === AddressValidationStatusEnum.CLEANSED) {
                        result.type = 2;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.EXACT_MATCH && result.OrganizationAddress.status === AddressValidationStatusEnum.ERROR) {
                        result.type = 3;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.CLEANSED && result.OrganizationAddress.status === AddressValidationStatusEnum.EXACT_MATCH) {
                        result.type = 4;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.CLEANSED && result.OrganizationAddress.status === AddressValidationStatusEnum.CLEANSED) {
                        result.type = 5;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.CLEANSED && result.OrganizationAddress.status === AddressValidationStatusEnum.ERROR) {
                        result.type = 6;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.ERROR && result.OrganizationAddress.status === AddressValidationStatusEnum.EXACT_MATCH) {
                        result.type = 7;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.ERROR && result.OrganizationAddress.status === AddressValidationStatusEnum.CLEANSED) {
                        result.type = 8;
                    }
                    else if (result.MemberAddress.status === AddressValidationStatusEnum.ERROR && result.OrganizationAddress.status === AddressValidationStatusEnum.ERROR) {
                        result.type = 9;
                    }
                    return resolve(result);
                });
            });
        } else {
            addressValidation(addressOrganization, resp => {
                result.OrganizationAddress = { status: resp.status, validateAddress: resp.validateAddress };
                if (result.OrganizationAddress.status === AddressValidationStatusEnum.EXACT_MATCH) {
                    result.type = 1;
                }
                else if (result.OrganizationAddress.status === AddressValidationStatusEnum.CLEANSED) {
                    result.type = 2;
                }
                else if (result.OrganizationAddress.status === AddressValidationStatusEnum.ERROR) {
                    result.type = 3;
                }
                return resolve(result);
            });
        }
    });
}

export const getStateByStateCode = (addressList, stateCode) => {
    let state = addressList.find(stateObject => stateObject.value === stateCode);
    return state ? state.text : null;
}

export const getValidZipCode = (zipcode) => {
    return zipcode.split("-")[0];
}

export const AddressEditEnum = {
    MEMBER_SUGGESTED: 'validatedAddressChange',
    ORGANIZATION_SUGGESTED: 'validatedOrganizationAddressChange',
    MEMBER_ENTERED: 'providedAddressChange',
    ORGANIZATION_ENTERED: 'providedOrganizationAddressChange'
}

export const AddressTypeEnum = {
    PERSONAL: 'Personal Address',
    MEMBER: 'Representative Address',
    ORGANIZATION: 'Organization Address',
    BOTH: 'Both Address'
}

export const getUUIDNumberFromLocalStorage = url => {
    const getUUIDObject = JSON.parse(window.localStorage.getItem('storeUUIDForAllApiCall') ?? "{}");
    let UuidInEntriesForm = Object.entries(getUUIDObject)
    for (let [key, value] of UuidInEntriesForm) {
        if (key === url) {
            return value;
        }
    }
}

export const convertImageUrlToBase64 = async (imageUrl) => {
    const fechedImage = await fetch(imageUrl);
    const blob = await fechedImage.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            resolve(reader.result);
        }, false);
        reader.onerror = () => {
            return reject(this);
        };
        reader.readAsDataURL(blob);
    })
}

export const checkIdentityDelegation = () => {
    return cacheManager.getItem(USER_INFO) ? get(JSON.parse(cacheManager.getItem(USER_INFO)), IDENTITY_DELEGATION_FLAG) : null;
}

export const DATADOG_LOG_TYPE = {
    'debug': 'debug',
    'info': 'info',
    'warn': 'warn',
    'error': 'error'
}
export const datadogLogger = (message, obj = {}, status = LOG_TYPE.debug, error) => {
    const genricObj = {
        info: obj,
        date: (new Date()).toTimeString(),
    };
    datadogLogs.logger.log(message, genricObj, status, error);
}

export default {
    setSession,
    getSession,
    removeSession,
    clearSession,
    getFormatDate,
    setRedirectUrl,
    getRedirectUrl,
    memberNameFormat,
    required,
    requiredEmail,
    incorrectEmail,
    encodeText,
    isLoggerEnabled,
    getEncodedWorkItemNumberWithoutWK,
    cacheManager,
    downloadFile,
    sessionStorageManager,
    encryptText,
    hasOpenAuthToken,
    AddGoogleDocLog,
    getcurday,
    getUserInfo,
    getMessage,
    convertBytesToKB,
    days_diff,
    validateCompanySearch,
    multipleOrganizationList,
    getEmailDomain,
    scrollToTop,
    getTimeInfo,
    scrollToError,
    validateDate,
    characterCount,
    isLocalhost,
    isOKTAStateAndNonceExists,
    getUserAgent,
    encrypt,
    decrypt,
    scrollToErrorOnFormHandler,
    isOKTAStorageAvailable,
    isOKTAStorageAvailableWithRequiredkey,
    getStandardsUrlFromAssetId,
    getFileType,
    doAddressValidation,
    AddressEditEnum,
    AddressTypeEnum,
    getStateByStateCode,
    getValidZipCode,
    getUUIDNumberFromLocalStorage,
    convertImageUrlToBase64,
    checkIdentityDelegation,
    DATADOG_LOG_TYPE,
    datadogLogger
}
